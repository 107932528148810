import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { CoursePatchFormType, CourseFormType, CourseType } from "@/types/course/course-type";
import { AxiosError } from "axios";
import { getHeaderAuth } from "../handlers-helpers";

export async function courseAllHandler(): Promise<Array<CourseType> | void> {
  return await request(undefined, "GET", "course", undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function coursePostHandler(payload: CourseFormType): Promise<void> {
  return await request(undefined, "POST", "course", undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function coursePatchHandler(payload: CoursePatchFormType, course_id: number): Promise<void> {
  return await request(undefined, "PATCH", `course/${course_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function courseDeleteHandler(course_id: number): Promise<CourseType | void> {
  return await request(undefined, "DELETE", `course/${course_id}`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}
