
import { coursePostHandler } from "../handlers/course/course-plain-handlers";
import { CourseFormType } from "../types/course/course-type";
import { Options, Vue } from "vue-class-component";
import Category from "../components/Category.vue";
import { CategoryFormType } from "../types/helpers/category-type";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: {
    Category,
  },
})
export default class CourseNew extends Vue {
  course_form: CourseFormType = {
    name: "",
    abbreviation: "",
    categories: [],
  };

  title_wrong = false;
  abbreviation_wrong = false;
  button = "Založit!";

  new_category: CategoryFormType = {
    name: "",
    colour: "#000000",
  };

  async createCourse(): Promise<void> {
    let bad = false;
    if (this.course_form.name.length === 0) {
      bad = true;
      this.title_wrong = true;
    } else this.title_wrong = false;
    if (this.course_form.abbreviation.length === 0 || this.course_form.abbreviation.length > 8) {
      bad = true;
      this.abbreviation_wrong = true;
    } else this.abbreviation_wrong = false;
    if (bad) return;

    this.button = "Vytvářím!";
    const response = await coursePostHandler(this.course_form);
    if (response === undefined) return;
    showAlert("Úspěch", "Nový kurz úspěšně přidán.", AlertSeverities.success);
    await this.$router.push({ name: "Teacher" });
  }

  addCategory(): void {
    this.new_category.name = this.new_category.name.trim();
    if (this.new_category.name.length === 0) return;
    if (this.course_form.categories.some((e) => e.name === this.new_category.name)) return;
    this.course_form.categories.push(Object.assign({}, this.new_category));
  }
}
